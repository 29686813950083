import { useBreakpointValue } from '@thebouqs/ui';
import { useBreakpointReturnType } from './useBreakpoint.d';

/**
 * React hook used to get the current responsive media breakpoint.
 *
 * @method
 * @name useBreakpoint
 *
 * @param {string} defaultBreakpoint - sets a default breakpoint in non-window environments like SSR
 * default value ensures SSR+CSR consistency
 * Uses https://github.com/chakra-ui/chakra-ui/blob/next/packages/media-query/src/use-breakpoint.ts
 *
 * * @example
 * const { isDesktop } = useBreakpoint(); // false in SSR, true in desktop browser
 * const { isMobile } = useBreakpoint('sm'); // true in SSR, true on mobile browser, false on desktop browser
 */
export default function useBreakpoint(
  defaultBreakpoint = null
): useBreakpointReturnType {
  // Currently using both breakpoint index and breakpoint names
  // Documentation says useBreakpointValue supports breakpoint name, but debugging only seems to work with the index
  // https://chakra-ui.com/docs/styled-system/utility-hooks/use-breakpoint-value
  const currentBreakpoint = useBreakpointValue(
    {
      base: 'base',
      0: 'base',
      sm: 'sm',
      1: 'sm',
      md: 'md',
      2: 'md',
      lg: 'lg',
      3: 'lg',
      xl: 'xl',
      4: 'xl',
    },
    defaultBreakpoint
  ); // returns undefined in SSR unless you pass a defaultBreakpoint
  const isMobile = ['base', 'sm'].indexOf(currentBreakpoint) > -1;
  const isTablet = ['md'].indexOf(currentBreakpoint) > -1;
  const isSmallDesktop =
    ['lg'].indexOf(currentBreakpoint) > -1 && window.innerWidth < 1000;
  const isDesktop = ['lg', 'xl'].indexOf(currentBreakpoint) > -1;
  const isMobileTablet = ['base', 'sm', 'md'].indexOf(currentBreakpoint) > -1;
  const isTabletDesktop = ['md', 'lg', 'xl'].indexOf(currentBreakpoint) > -1;

  return {
    isMobile,
    isTablet,
    isSmallDesktop,
    isDesktop,
    isMobileTablet,
    isTabletDesktop,
  };
}
