import React, { useMemo, useEffect } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { SegmentAnalytics, NewRelic } from '@/constants/head';
import PageHeader from '@/components/PageHeader';
import PageFooter from '@/components/PageFooter';
import Breadcrumbs from '@/components/Breadcrumbs';
import { Box } from '@thebouqs/ui';
import usePageView from '@/hooks/usePageView';
import useSignifyd from '@/hooks/useSignifyd';
import {
  NODE_ENV,
  SF_URL,
  CDN_URL,
  TARGET_ENV,
  VERSION,
  SERVICE_WORKER_ON,
} from '@/constants/env';
import useLang from '@/hooks/useLang/useLang';
import AccessibilityLink from '@/components/AccessibilityLink';
import useTranslation from '@/hooks/useTranslation';
import useStatic from '@/hooks/useStatic';
import { Flex } from '@thebouqs/ui';
import ServiceWorkerManager from '@/components/ServiceWorkerManager';
import useCookies from '@/hooks/useCookies';
import isBrowser from '@/utils/isBrowser';
import { upsertCookies } from '@/lib/bouqsCookies';
import isDesktop from '@/hooks/useBreakpoint';
import LoginSignupBanner from '@/components/TopNav/NavHeader/LoginSignupBanner';
import useUser from '@/hooks/useUser';
import selectUserType from '@/store/user/selectors/selectUserType';
import useBreakpoint from '@/hooks/useBreakpoint';

import { useWindowDimensions } from '@/hooks/useWindowDimensions';
type Props = {
  children: any;
  name: string;
  controlGroup: string;
  showBreadcrumbs?: boolean;
};

const Page = ({
  children,
  name,
  showBreadcrumbs = true,
  controlGroup,
}: Props): JSX.Element => {
  const currentLocale = useLang();
  const { t } = useTranslation();
  const { getSessionId } = useSignifyd();
  const { pageData } = useStatic();
  const user = useUser();
  const { isLoggedIn } = selectUserType(user);
  const { isMobileTablet } = useBreakpoint();

  usePageView(name, controlGroup);
  const { width } = useWindowDimensions();

  const robotsMetaTag = useMemo(() => {
    let metaRobotsContent = '';

    if (!pageData.seo) {
      return '';
    }

    const { seo_index, seo_follow } = pageData.seo;

    if (!seo_follow) {
      metaRobotsContent = 'nofollow';
    }

    if (!seo_follow && !seo_index) {
      metaRobotsContent = `${metaRobotsContent} `;
    }

    if (!seo_index) {
      metaRobotsContent = `${metaRobotsContent}noindex`;
    }
    if (seo_index && seo_follow) {
      return null;
    }
    return <meta name="robots" content={metaRobotsContent} />;
  }, [pageData]);

  const { cookies, updateCookie, refreshCookies } = useCookies();
  const { query } = useRouter();

  useEffect(() => {
    if (!isBrowser()) {
      return;
    }

    const cookiesChanged = upsertCookies(query, cookies, updateCookie);
    if (cookiesChanged) {
      refreshCookies();
    }
  }, [cookies, updateCookie, refreshCookies, query]);

  const mxl = name === 'Product-Detail' && isDesktop ? '10%' : '3%';

  const installABTasty =
    ['staging', 'production'].includes(TARGET_ENV) ||
    ['staging', 'production'].includes(NODE_ENV);

  return (
    <>
      <Head>
        <title key={pageData.seo?.browser_title || 'browser title fallback'}>
          {`${pageData.seo?.browser_title ?? ''} - The Bouqs Co.`}
        </title>
        <meta content={currentLocale} property="locale" />
        <meta content="breadcrumbs json object" property="breadcrumbs" />
        <meta
          content="bfc19a3763b0490d9b7e9ce3f2d9c079"
          name="p:domain_verify"
        />
        <meta
          content="EbbJ_M0ORNg5KouoQkqf6WLOPJkgPVhdu2P_q9Qlz1E"
          name="google-site-verification"
        />
        <meta content={TARGET_ENV || NODE_ENV} property="environment" />
        <meta name="version" content={VERSION} />
        {robotsMetaTag}]
        <meta
          name="keywords"
          content={
            pageData.seo?.meta_keywords ||
            'flower delivery, send flowers, order flowers, online flowers, bouquets'
          }
        />
        <meta
          name="description"
          content={
            pageData.seo?.meta_description ||
            'Send responsibly sourced flower arrangements. Shop our full collection of farm-fresh floral arrangements available for delivery.'
          }
        />
        <meta name="theme-color" content="#ffffff" />
        <meta content={pageData.path} property="og:url" />
        <meta
          content={`${pageData.seo?.browser_title ?? ''} - The Bouqs Co.`}
          property="og:title"
        />
        <meta content="website" property="og:type" />
        <meta content={`${CDN_URL}/logo.png`} property="og:image" />
        <link rel="icon" type="image/x-icon" href={`${CDN_URL}/favicon.ico`} />
        <link
          rel="apple-touch-icon"
          type="image/png"
          href={`${CDN_URL}/apple-touch-icon.png`}
          sizes="180x180"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${CDN_URL}/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${CDN_URL}/favicon-16x16.png`}
          sizes="16x16"
        />
        <link
          href={`${SF_URL}${pageData?.path ?? window.location.pathname}`}
          rel="canonical"
        />
      </Head>
      <script
        id="newrelic"
        dangerouslySetInnerHTML={{
          __html: NewRelic,
        }}
      />
      {/* Block loading analytics and abtasty for users identified as bots. */}
      {cookies['suppress-analytics'] !== 'true' ? (
        <Script
          id="segment-analytics"
          dangerouslySetInnerHTML={{
            __html: SegmentAnalytics,
          }}
        />
      ) : null}
      {cookies['suppress-analytics'] !== 'true' && installABTasty ? (
        <Script
          id="abtasty"
          src="https://try.abtasty.com/f5be8069e79a6bd900512bf3846025ba.js"
        />
      ) : null}
      <Script
        src="https://cdn-scripts.signifyd.com/api/script-tag.js"
        id="sig-api"
        strategy="lazyOnload"
        data-order-session-id={getSessionId()}
      />
      <AccessibilityLink link="#main">
        {t('common.accessibilityLinks.main')}
      </AccessibilityLink>
      <AccessibilityLink link="#nav">
        {t('common.accessibilityLinks.nav')}
      </AccessibilityLink>
      <AccessibilityLink link="#footer-nav">
        {t('common.accessibilityLinks.footer')}
      </AccessibilityLink>
      <AccessibilityLink link="/accessibility">
        {t('common.accessibilityLinks.info')}
      </AccessibilityLink>
      <PageHeader />
      <Flex justifyContent="center">
        <Box
          className="main-content"
          width="100%"
          maxWidth="8xl"
          mx={['auto', null, '3%', mxl]}
          px={['md', null, null, 'none']}
          id="top">
          {showBreadcrumbs ? <Breadcrumbs /> : null}
          {!isLoggedIn && isMobileTablet ? (
            <div id="login-signup-banner-wrapper">
              <LoginSignupBanner isMobileTablet={isMobileTablet} />
            </div>
          ) : null}
          <Box as="main" my="none" id="main" role="main">
            {children}
          </Box>
          {(name !== 'Product-Detail' || width > 769) && <PageFooter />}
        </Box>
      </Flex>
      {SERVICE_WORKER_ON ? <ServiceWorkerManager /> : null}
    </>
  );
};

export default Page;
