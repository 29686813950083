import React from 'react';
import styled from '@emotion/styled';
import dynamic from 'next/dynamic';
import { Flex, Box } from '@thebouqs/ui';
import NavLogo from '../NavLogo';
import NavHeaderMenu from './NavHeaderMenu';
import { NavHeaderProps } from './NavHeader.d';
import LoginSignupBanner from './LoginSignupBanner';
import useUser from '@/hooks/useUser';
import selectUserType from '@/store/user/selectors/selectUserType';

const StyledFlex = styled(Flex)`
  max-width: 300px !important;
  width: 100%;
  @media (min-width: 1289px) {
    max-width: 550px !important;
    width: 100%;
  }
`;

const DrawerMenu = dynamic(() => import('../DrawerMenu'), {
  ssr: false,
});

const NavHeader = ({
  isDesktop,
  isMobileTablet,
}: NavHeaderProps): JSX.Element => {
  const user = useUser();
  const { isLoggedIn } = selectUserType(user);

  return (
    <Flex
      className="page-header__navigation"
      align="center"
      justify={!isLoggedIn ? 'space-between' : 'flex-end'}
      flexDirection="row"
      width="full"
      maxWidth="8xl"
      height={['16', null, null, '20']}
      paddingX={[null, null, null, '6']}
      marginX="auto"
      position="relative">
      {isMobileTablet && (
        <Box className="page-header__drawer">
          <DrawerMenu />
        </Box>
      )}
      {isDesktop && !isLoggedIn ? (
        <StyledFlex>
          <LoginSignupBanner isMobileTablet={isMobileTablet} />
        </StyledFlex>
      ) : null}
      <NavLogo />
      <NavHeaderMenu isDesktop={isDesktop} />
    </Flex>
  );
};

export default NavHeader;
