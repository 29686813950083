import useTranslation from '@/hooks/useTranslation';
import React, { useState } from 'react';
import { LoginSignupBannerProps } from './LoginSignupBanner.d';
import { Button, Flex, Box, useBreakpointValue } from '@thebouqs/ui';
import LoginSignupModal from '@/components/LoginSignupModal/LoginSignupModal';

const LoginSignupBanner = ({
  isMobileTablet,
}: LoginSignupBannerProps): JSX.Element | null => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoginMode, setIsLoginMode] = useState(true);
  const loginDiscountPrice = process.env.SHIPPING_SAVINGS_PRICE || 7;
  const buttonSize = useBreakpointValue({ base: 'sm', md: 'md' });

  const openModal = (isLogin: boolean) => {
    setIsLoginMode(isLogin);
    setModalOpen(true);
  };

  const closeModal = () => setModalOpen(false);

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent={['space-between', 'space-between', 'flex-start']}
        borderTop={['1px solid #E8E8EB', 'none']}
        borderBottom={['1px solid #E8E8EB', 'none']}
        paddingY={['8px', '0']}>
        <Box
          maxWidth={['140px', '235px']}
          style={{
            color: '#144B3C',
            fontWeight: '600',
            marginRight: '0.5rem',
          }}>
          <>
            {t('loginSignUpBanner.logInSignUp')}{' '}
            {t('loginSignUpBanner.discount', {
              shippingSavingsPrice: loginDiscountPrice,
            })}
          </>
        </Box>
        <Flex gap="12px">
          <Button
            size={buttonSize}
            colorScheme="green"
            height="36px"
            width="71px"
            letterSpacing="0.32px"
            onClick={() => openModal(true)}
            margin={['0, 0.3rem']}
            fontSize={['14px']}
            padding={['8px 12px', '8px 12px']}
            lineHeight="20px"
            border={'1px solid #085b45'}>
            LOG IN
          </Button>
          <Button
            onClick={() => openModal(false)}
            backgroundColor="white"
            background="transparent"
            fontSize={['14px', '14px']}
            padding={['8px 12px', '8px 12px']}
            height="36px"
            width="81px"
            letterSpacing="0.32px"
            border="1px solid #144B3C !important"
            borderRadius="2px"
            color="#144B3C"
            outline="none"
            fontWeight="600"
            lineHeight="20px">
            SIGN UP
          </Button>
        </Flex>
      </Flex>

      <LoginSignupModal
        isOpen={isModalOpen}
        onClose={closeModal}
        isLoginMode={isLoginMode}
        setIsLoginMode={setIsLoginMode}
        isMobileTablet={isMobileTablet}
      />
    </>
  );
};

export default LoginSignupBanner;
