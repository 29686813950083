import { SF_API_V3 } from '@/constants/env';
import axios from 'axios';

export const login = async (email: string, password: string): Promise<any> => {
  try {
    const response = await axios.post(
      `${SF_API_V3}/login`,
      {
        email,
        password,
      },
      { withCredentials: true }
    );
    if (response.data) {
      await fetchCurrentUser();
      return response.data;
    }
  } catch (error: any) {
    throw new Error(error.response?.data?.error || 'Login failed');
  }
};

export const signup = async (email: string, password: string): Promise<any> => {
  try {
    const response = await axios.post(
      `${SF_API_V3}/users`,
      {
        email,
        password,
      },
      { withCredentials: true }
    );
    if (response.data) {
      await fetchCurrentUser();
      return response.data;
    }
  } catch (error: any) {
    throw new Error(error.response?.data?.error || 'Signup failed');
  }
};

const fetchCurrentUser = async () => {
  try {
    const response = await axios.get(`${SF_API_V3}/current_user.json`, {
      withCredentials: true,
      headers: {
        // 'X-CSRF-Token': browserCookies.get(COOKIES.CSRF_TOKEN) || '',
        'X-Page-Referrer': document.referrer,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch current user data:', error);
  }
};
