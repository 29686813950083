import React from 'react';
import { Heading, Text } from '@thebouqs/ui';
import { ModalHeaderProps } from './ModalHeader.d';

const ModalHeader = ({ isLoginMode, t }: ModalHeaderProps): JSX.Element => (
  <>
    <Heading
      as="h2"
      size="lg"
      textAlign="center"
      fontFamily="playfair display,serif"
      fontSize="25px"
      fontWeight="700"
      letterSpacing=".012em">
      {isLoginMode
        ? t('loginSignUpModal.login.title')
        : t('loginSignUpModal.signup.title')}
    </Heading>
    {isLoginMode && (
      <Text
        textAlign="center"
        marginBottom="6"
        fontSize="14px"
        fontWeight="600"
        letterSpacing=".15em"
        fontFamily="proxima-nova,sans-serif"
        textTransform="uppercase">
        {t('loginSignUpModal.login.subtitle')}
      </Text>
    )}
  </>
);

export default ModalHeader;
