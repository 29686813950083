import React, { useEffect, useState } from 'react';
import { NotificationBannerProps } from './NotificationBanner.d';

const NotificationBanner = ({
  type,
  message,
  duration = 5000,
  onClose,
}: NotificationBannerProps): JSX.Element => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      if (onClose) {
        onClose();
      }
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  if (!visible) return <></>;

  const getBackgroundColor = (type: string) => {
    switch (type) {
      case 'success':
        return '#28a745';
      case 'error':
        return '#dc3545';
      case 'warning':
        return '#ffc107';
      case 'info':
        return '#17a2b8';
      default:
        return '#17a2b8';
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '12px 24px',
        margin: '16px auto',
        fontWeight: 600,
        fontSize: '14px',
        color: 'white',
        borderRadius: '8px',
        maxWidth: '100%',
        position: 'absolute',
        zIndex: 9999,
        backgroundColor: getBackgroundColor(type),
        justifyContent: 'center',
        width: '100%',
        top: '0',
      }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33"
        height="33"
        viewBox="0 0 33 33">
        <g fill="none" fillRule="evenodd">
          <path
            fill="#FFF"
            d="M16.5 33C25.614 33 33 25.614 33 16.5S25.614 0 16.5 0 0 7.386 0 16.5 7.386 33 16.5 33zM14.844 6.574a1.655 1.655 0 1 1 3.312 0v13.233a1.655 1.655 0 1 1-3.312 0V6.574zM16.5 24.77a1.655 1.655 0 1 1 0 3.312 1.655 1.655 0 0 1-1.656-1.656c0-.917.74-1.656 1.656-1.656z"
          />
        </g>
      </svg>
      <span
        style={{
          fontSize: '14px',
          fontWeight: 500,
          marginRight: '10px',
          marginLeft: '1.5rem',
        }}>
        {message}
      </span>
      <span
        style={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          fontSize: '16px',
          position: 'absolute',
          right: '10px',
        }}
        onClick={() => setVisible(false)}>
        <svg
          width="16"
          height="14"
          viewBox="0 0 16 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g id="icon">
            <g id="Group 310">
              <line
                id="Line 1"
                x1="2.70711"
                y1="1.29289"
                x2="14.7071"
                y2="13.2929"
                stroke="white"
                strokeWidth="2"
              />
              <line
                id="Line 2"
                y1="-1"
                x2="16.9706"
                y2="-1"
                transform="matrix(-0.707107 0.707107 0.707107 0.707107 14 2)"
                stroke="white"
                strokeWidth="2"
              />
            </g>
          </g>
        </svg>
      </span>
    </div>
  );
};

export default NotificationBanner;
