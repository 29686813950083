import { createIcon } from '@thebouqs/icons';

const PlayIcon = createIcon({
  displayName: 'playIcon',
  viewBox: '0 0 31 31',
  defaultProps: {
    w: { base: '44px', md: '72px !important'},
    h: { base: '44px', md: '72px !important'},
  },
  path: (
    <g
     id="layer1"
     >
      <path
        id="path111" 
        style={{ fill: '#ffffff', fillOpacity:0.83, strokeWidth:0.269356 }}
        d="M 15.394926,0 A 15.395135,15.395135 0 0 0 1.200611e-8,15.394926 15.395135,15.395135 0 0 0 15.394926,30.789852 15.395135,15.395135 0 0 0 30.789852,15.394926 15.395135,15.395135 0 0 0 15.394926,0 Z m -5.227071,8.29045 6.152596,3.552238 6.152596,3.552238 -6.152596,3.552238 -6.152596,3.552238 v -7.104476 z" />
    </g>
  ),
});

export default PlayIcon;
