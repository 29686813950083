import { Collapse } from '@chakra-ui/transition';
import { ChevronDownIcon, ChevronUpIcon } from '@thebouqs/icons';
import { Box, Flex, Heading, IconButton } from '@thebouqs/ui';
import React, { useEffect, useState } from 'react';
import {
  AccordionProps,
  AccordionItemProps,
  AccordionItemHeadingProps,
  AccordionItemPanelProps,
} from './Accordion.d';

// Accordion component (wrapper for all accordion items)
const Accordion: React.FC<AccordionProps> = ({ children, items, index }) => {
  const [openIndexes, setOpenIndexes] = useState<number[]>([]);

  // Function to get stored accordion state from localStorage
  const getAccordionStateFromStorage = () => {
    const storedState = localStorage.getItem('accordionState');
    return storedState ? JSON.parse(storedState) : [];
  };

  // Save accordion state to localStorage
  const saveAccordionStateToStorage = (state: number[]) => {
    localStorage.setItem('accordionState', JSON.stringify(state));
  };

  // Update openIndexes based on addonSelected and index
  useEffect(() => {
    if (items) {
      const newOpenIndexes: number[] = [];
      items.forEach((item, index) => {
        // If addon_selected is not available, expand the item
        if (!item.addon_selected) {
          newOpenIndexes.push(index);
        }
      });

      setOpenIndexes(newOpenIndexes); // Set open items based on the addon_selected condition
      saveAccordionStateToStorage(newOpenIndexes); // Save the state to localStorage
    } else if (index !== undefined && !isNaN(index)) {
      setOpenIndexes([index]); // Open specific index if provided
      saveAccordionStateToStorage([index]); // Save to storage
    } else {
      const storedState = getAccordionStateFromStorage(); // Retrieve stored state on mount
      setOpenIndexes(storedState); // Rehydrate state
    }
  }, [items, index]);

  useEffect(() => {
    return () => setOpenIndexes([]); // Reset state on unmount
  }, []);

  const toggleItem = (itemIndex: number) => {
    setOpenIndexes(prevIndexes => {
      const newIndexes = prevIndexes.includes(itemIndex)
        ? prevIndexes.filter(i => i !== itemIndex) // Close if already open
        : [...prevIndexes, itemIndex]; // Open new item

      saveAccordionStateToStorage(newIndexes); // Save new state to storage
      return newIndexes;
    });
  };

  return (
    <Box as="div" role="region">
      {React.Children.map(children, (child, childIndex) =>
        React.isValidElement(child)
          ? React.cloneElement(child, {
              isOpen: openIndexes.includes(childIndex),
              onToggle: () => toggleItem(childIndex),
            })
          : child
      )}
    </Box>
  );
};

// AccordionItem component (single item in accordion)
export const AccordionItem: React.FC<AccordionItemProps> = ({
  isOpen,
  onToggle,
  children,
}) => {
  return (
    <Box>
      {React.Children.map(children, child =>
        React.isValidElement(child)
          ? React.cloneElement(child, { isOpen, onToggle })
          : child
      )}
    </Box>
  );
};

// AccordionItemHeading component (clickable heading)
export const AccordionItemHeading: React.FC<AccordionItemHeadingProps> = ({
  title,
  isOpen,
  onToggle,
}) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      cursor="pointer"
      onClick={onToggle}
      marginBottom="16px">
      <Heading
        as="h3"
        fontSize="1.14rem"
        fontFamily="proxima-nova, sans-serif"
        fontWeight="600"
        letterSpacing="0.1em">
        {title}
      </Heading>
      <IconButton
        aria-label="Toggle Accordion"
        icon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        variant="ghost"
        fontSize="1.6rem"
        border="none"
      />
    </Flex>
  );
};

// AccordionItemPanel component (collapsible content)
export const AccordionItemPanel: React.FC<AccordionItemPanelProps> = ({
  isOpen,
  children,
}) => {
  return (
    <Collapse in={isOpen} animateOpacity>
      <Box>{children}</Box>
    </Collapse>
  );
};

export default Accordion;
