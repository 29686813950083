import NextLink from 'next/link';
import sanitizeHtml from 'sanitize-html';

import { Box, Flex, Ribbon } from '@thebouqs/ui';
import { McodeRibbonBlockProps } from './McodeRibbonBlock.d';
import { useRouter } from 'next/router';
import useAnalytics from '@/hooks/useAnalytics';
import { createBouqsCdnIcon } from '@thebouqs/icons';
import RibbonBlockSkeleton from '../RibbonBlock/RibbonBlockSkeleton';
import TrackView from '@/components/Track/TrackView';
import useUser from '@/hooks/useUser';
import { getCookie } from 'cookies-next';

const SaleTag = createBouqsCdnIcon('saleTag');

const MCodeRibbonBlock = (props: McodeRibbonBlockProps): JSX.Element => {
  const {
    data: { text, link_url, background, color },
    category,
  } = props;
  const { track } = useAnalytics();
  const route = useRouter();
  const mcode = (route?.query?.mcode as string) || getCookie('mcode');
  const { is_subscriber: isSubscriber } = useUser();
  const mcodePlaceholder = text.includes('{{mcode}}');

  const sanitizeOptions = mcodePlaceholder
    ? { allowedTags: [] }
    : {
        allowedTags: ['span'],
        allowedAttributes: {
          span: ['class'],
        },
      };

  const sanitized = sanitizeHtml(text, sanitizeOptions);
  const textAroundMcode = sanitized.split(/{{mcode}}/);
  const ribbonText = text.replace(/{{mcode}}/, mcode);
  const plainText = sanitizeHtml(ribbonText, {
    allowedTags: [],
  });

  const trackViewProps = {
    actionName: 'Mcode Ribbon Content Block Viewed',
    category: category ?? 'All',
    label: plainText,
  };

  const onClickHandler = () => {
    track('Mcode Ribbon Content Block Clicked', {
      category: category?.length ? category : 'All',
      label: plainText,
      is_subscriber: isSubscriber || false,
    });
  };

  return props?.data ? (
    <TrackView {...trackViewProps}>
      <NextLink href={link_url}>
        <Ribbon
          link={link_url}
          backgroundColor={background}
          color={color}
          onClickFn={onClickHandler}
          jsxElement={
            <Flex
              className="mcode-ribbon-block"
              flexDirection={['column', 'row']}
              justifyContent="center"
              align="center">
              <Flex justifyContent="center" align="center">
                <SaleTag
                  display={['none', null, 'block']}
                  boxSize="6"
                  color={color}
                  mx="2"
                />
                {mcodePlaceholder && <Box>{textAroundMcode[0]}</Box>}
                {!mcodePlaceholder && (
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: textAroundMcode[0],
                    }}></Box>
                )}
                {mcodePlaceholder && (
                  <Box p="xs" m="xs" backgroundColor={color} color={background}>
                    {mcode}
                  </Box>
                )}
              </Flex>
              <Box>{textAroundMcode[1]}</Box>
            </Flex>
          }
        />
      </NextLink>
    </TrackView>
  ) : (
    <RibbonBlockSkeleton />
  );
};

export default MCodeRibbonBlock;
