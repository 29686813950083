import React, { useState, useEffect } from 'react';
import { Modal, ModalCloseButton, Box, Text, Button } from '@thebouqs/ui';
import useTranslation from '@/hooks/useTranslation';
import { LoginSignupModalProps } from './LoginSignupModal.d';
import { login, signup } from '@/lib/authService';
import ModalHeader from './ModalHeader';
import ToggleButtons from './ToggleButtons';
import NotificationBanner from '@/components/NotificationBanner';

const LoginSignupModal = ({
  isOpen: propsIsOpen,
  onClose,
  isLoginMode,
  setIsLoginMode,
  isMobileTablet,
}: LoginSignupModalProps): JSX.Element => {
  const { t } = useTranslation();
  const loginDiscountPrice = process.env.SHIPPING_SAVINGS_PRICE || 7;
  const signupDiscountPrice = process.env.SHIPPING_SAVINGS_PRICE || 7;
  const [email, setEmail] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [emailTouched, setEmailTouched] = useState(false);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [passwordTouched, setPasswordTouched] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const [, setIsOpen] = useState(propsIsOpen);

  const validateEmail = (value: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError(null);
    }
  };

  const validatePassword = (value: string) => {
    if (!isLoginMode) {
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{7,}$/;
      if (!passwordRegex.test(value)) {
        setPasswordError(
          'Enter at least 7 characters with upper and lowercase letters and numbers.'
        );
      } else {
        setPasswordError(null);
      }
    }
  };

  const handleEmailBlur = () => {
    setEmailTouched(true);
    validateEmail(email);
  };

  const handlePasswordBlur = () => {
    setPasswordTouched(true);
    validatePassword(password);
  };

  useEffect(() => {
    setIsOpen(propsIsOpen);
  }, [propsIsOpen]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage(null);
    setLoading(true);

    if (!emailTouched) {
      setEmailTouched(true);
      validateEmail(email);
    }

    if (!passwordTouched && !isLoginMode) {
      setPasswordTouched(true);
      validatePassword(password);
    }

    if (emailError || passwordError) {
      setLoading(false);
      return;
    }

    try {
      let response;
      if (isLoginMode) {
        response = await login(email, password);
      } else {
        response = await signup(email, password);
      }

      if (response) {
        window.location.reload();
      }
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {errorMessage && (
        <NotificationBanner
          type="error"
          message={errorMessage}
          duration={5000}
        />
      )}
      <Modal
        label={
          isLoginMode
            ? t('loginSignUpModal.login.title')
            : t('loginSignUpModal.signup.title')
        }
        open={propsIsOpen}
        size="sm"
        CloseButton={
          <ModalCloseButton label="close-modal" variant="normal" size="sm" />
        }
        callback={onClose}
        stylesOverride={{
          closeButton: {},
          body: { padding: isMobileTablet ? '20px' : '10px' },
          dialog: {
            top: isMobileTablet ? '45%' : '38%',
            position: 'fixed',
            maxWidth: isMobileTablet ? '300px' : '560px',
            width: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
            overflow: 'hidden',
            boxSizing: 'border-box',
            padding: isMobileTablet ? '20px' : '10px',
          },
        }}>
        <Box
          width="100%"
          maxWidth={isMobileTablet ? '280px' : '448px'}
          margin="auto"
          padding={isMobileTablet ? '8px 0' : '8px 50px'}
          overflow="hidden"
          boxSizing="border-box">
          <ModalHeader isLoginMode={isLoginMode} t={t} />

          <p
            style={{
              textAlign: 'center',
              fontWeight: '600',
              color: '#154b3c',
              marginBottom: '16px',
            }}>
            {' '}
            {isLoginMode
              ? t('loginSignUpModal.discount', {
                  shippingSavingsPrice: loginDiscountPrice,
                })
              : t('loginSignUpModal.signupDiscount', {
                  shippingSavingsPrice: signupDiscountPrice,
                })}
          </p>

          <ToggleButtons
            isLoginMode={isLoginMode}
            setIsLoginMode={() => setIsLoginMode(!isLoginMode)}
            t={t}
          />
          <Box as="form" onSubmit={handleSubmit} marginBottom="4">
            <div style={{ position: 'relative' }}>
              <label
                htmlFor="email"
                style={{
                  display: 'block',
                  marginBottom: '8px',
                  fontSize: '14px',
                  fontWeight: '400',
                  color: '#333',
                  fontFamily: 'proxima-nova,sans-serif',
                }}>
                {t('loginSignUpModal.email')}
              </label>
              <input
                placeholder="name@example.com"
                id="email"
                type="email"
                value={email}
                onBlur={handleEmailBlur}
                onChange={e => setEmail(e.target.value)}
                required
                style={{
                  width: '100%',
                  padding: '12px',
                  marginBottom: '10px',
                  border: emailError ? '1px solid red' : '1px solid #C4D2CE',
                  borderRadius: '4px',
                  fontSize: '14px',
                  color: '#333',
                }}
              />
              {emailError && (
                <p
                  style={{
                    color: 'red',
                    fontSize: '12px',
                    marginTop: '5px',
                    position: 'absolute',
                    top: '55px',
                    left: '7px',
                    background: '#fff',
                    transition: 'all .2s ease',
                    padding: '0 3px',
                  }}>
                  {emailError}
                </p>
              )}
            </div>
            <div style={{ position: 'relative' }}>
              <label
                htmlFor="password"
                style={{
                  display: 'block',
                  marginBottom: '8px',
                  fontSize: '14px',
                  fontWeight: '400',
                  color: '#333',
                  fontFamily: 'proxima-nova,sans-serif',
                }}>
                {t('loginSignUpModal.password')}
              </label>
              <input
                id="password"
                type={passwordVisible ? 'text' : 'password'}
                value={password}
                onBlur={handlePasswordBlur}
                onChange={e => setPassword(e.target.value)}
                placeholder={t('password')}
                style={{
                  width: '100%',
                  padding: '12px',
                  marginBottom: '10px',
                  border:
                    passwordError && !isLoginMode
                      ? '1px solid red'
                      : '1px solid #C4D2CE',
                  borderRadius: '4px',
                  fontSize: '14px',
                  color: '#333',
                }}
              />
              {!isLoginMode && passwordError && passwordTouched && (
                <p
                  style={{
                    color: 'red',
                    fontSize: '12px',
                    marginTop: '5px',
                    position: 'absolute',
                    top: '55px',
                    left: '10px',
                    background: '#fff',
                    transition: 'all .2s ease',
                    padding: '0 3px',
                  }}>
                  {passwordError}
                </p>
              )}
            </div>
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                togglePasswordVisibility();
              }}
              style={{
                color: '#144B3C',
                fontWeight: '700',
                marginTop: '5px',
                marginBottom: '16px',
                cursor: 'pointer',
                textAlign: 'right',
                display: 'block',
                textDecoration: 'none',
                fontSize: '11.9px',
                letterSpacing: '1.19px',
              }}
              onMouseOver={e =>
                (e.currentTarget.style.textDecoration = 'underline')
              }
              onMouseOut={e => (e.currentTarget.style.textDecoration = 'none')}>
              {passwordVisible
                ? t('loginSignUpModal.hidePassword')
                : t('loginSignUpModal.showPassword')}
            </a>
            <Button
              type="submit"
              variant="solid"
              colorScheme="green"
              width="full"
              disabled={loading}>
              {isLoginMode
                ? t('loginSignUpModal.login.submit')
                : t('loginSignUpModal.signup.submit')}
            </Button>
            {isLoginMode && (
              <a
                href="/forgot_password"
                style={{
                  color: '#144B3C',
                  fontWeight: '700',
                  marginTop: '20px',
                  cursor: 'pointer',
                  textAlign: 'right',
                  display: 'block',
                  textDecoration: 'none',
                  fontSize: '11.9px',
                  letterSpacing: '1.19px',
                }}
                onMouseOver={e =>
                  (e.currentTarget.style.textDecoration = 'underline')
                }
                onMouseOut={e =>
                  (e.currentTarget.style.textDecoration = 'none')
                }>
                {t('loginSignUpModal.forgotPassword')}
              </a>
            )}

            {!isLoginMode && (
              <Text
                textAlign="center"
                marginTop="16px"
                fontSize="12px"
                color="#333"
                style={{
                  fontWeight: '400',
                }}>
                {t('loginSignUpModal.termsAndCondition')}{' '}
                <a
                  href="/pages/terms"
                  style={{
                    color: '#144B3C',
                    textDecoration: 'underline',
                    fontSize: '11px',
                  }}>
                  Terms of Use
                </a>{' '}
                and{' '}
                <a
                  href="/pages/privacy"
                  style={{
                    color: '#144B3C',
                    textDecoration: 'underline',
                    fontSize: '11px',
                  }}>
                  Privacy Policy
                </a>
              </Text>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default LoginSignupModal;
