import dynamic from 'next/dynamic';
import { Link } from '@thebouqs/ui';
import { useRouter } from 'next/router';
import { MenuLinkProps } from './MenuLink.d';
import axios from 'axios';
import ROUTES from '@/routes';
import { useState } from 'react';
import CartDrawer from '@/components/Product/CartDrawer';
import useUser from '@/hooks/useUser';
const { getCartEndpoint } = ROUTES;

const SubMenu = dynamic(() => import('../SubMenu'), { ssr: false });

const MenuLink = ({
  linkContent,
  linkChildren,
  linkPath,
  topLinkIndex,
  isDesktop,
  name,
}: MenuLinkProps): JSX.Element => {
  const { is_subscriber, subscription_activation_used } = useUser();
  const router = useRouter();
  const [cartData, setCartData] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const eligibleForSubs =
    !subscription_activation_used && (is_subscriber ?? false) === false;

  const isPDP = router.pathname.includes(
    '/[category_type]/[category_slug]/[product_slug]'
  );

  const handleClick = async (event: React.MouseEvent) => {
    if (name === 'Review Order' && isPDP) {
      event.preventDefault();
      try {
        const response = await axios.get(getCartEndpoint);
        const { data } = response;
        setCartData(data);
      } catch (error) {
        console.error('Failed to fetch cart:', error);
      }
    }
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    setCartData(null);
  };

  if (isDesktop && linkChildren) {
    return (
      <SubMenu
        topLinkContent={linkContent}
        topLinkIndex={topLinkIndex}
        topLinkPath={linkPath}
        subMenuLinks={linkChildren}
      />
    );
  } else {
    return (
      <>
        <Link href={linkPath} fontWeight="semibold" onClick={handleClick}>
          {linkContent}
        </Link>
        <CartDrawer
          isDrawerOpen={cartData == null ? false : true}
          cartProducts={cartData}
          toggleDrawer={toggleDrawer}
          eligibleForSubs={eligibleForSubs}
        />
      </>
    );
  }
};

export default MenuLink;

// import dynamic from 'next/dynamic';
// import { Link } from '@thebouqs/ui';
// import { MenuLinkProps } from './MenuLink.d';

// const SubMenu = dynamic(() => import('../SubMenu'), { ssr: false });

// const MenuLink = ({
//   linkContent,
//   linkChildren,
//   linkPath,
//   topLinkIndex,
//   isDesktop,
//   name,
// }: MenuLinkProps): JSX.Element => {
//   if (isDesktop && linkChildren) {
//     return (
//       <SubMenu
//         topLinkContent={linkContent}
//         topLinkIndex={topLinkIndex}
//         topLinkPath={linkPath}
//         subMenuLinks={linkChildren}
//       />
//     );
//   } else {
//     return (
//       <Link href={linkPath} fontWeight="semibold">
//         {linkContent}
//       </Link>
//     );
//   }
// };

// export default MenuLink;
