import React from 'react';
import { Flex } from '@thebouqs/ui';
import { ToggleButtonsProps } from './ToggleButtons.d';

const ToggleButtons = ({
  isLoginMode,
  setIsLoginMode,
  t,
}: ToggleButtonsProps): JSX.Element => (
  <Flex justifyContent="center" marginBottom="6">
    <button
      onClick={() => setIsLoginMode(false)}
      style={{
        width: '50%',
        border: isLoginMode ? 'solid 2px #d2d8df' : 'solid 2px #333',
        padding: '8px 0',
        color: isLoginMode ? '#d2d8df' : '#333',
        backgroundColor: isLoginMode ? 'transparent' : 'rgba(0, 0, 0, 0)',
        cursor: 'pointer',
        fontSize: '0.9rem',
      }}>
      {t('loginSignUpModal.signup.button')}
    </button>
    <button
      onClick={() => setIsLoginMode(true)}
      style={{
        marginRight: '8px',
        width: '50%',
        border: isLoginMode ? 'solid 2px #333' : 'solid 2px #d2d8df',
        padding: '8px 0',
        color: isLoginMode ? '#333' : '#d2d8df',
        backgroundColor: isLoginMode ? 'transparent' : 'rgba(0, 0, 0, 0)',
        cursor: 'pointer',
      }}>
      {t('loginSignUpModal.login.button')}
    </button>
  </Flex>
);

export default ToggleButtons;
