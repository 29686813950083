import React, { forwardRef } from 'react';
import { Box } from '@thebouqs/ui';
import Price from '@/components/ProductSummary/Price';
import { ProductSummaryPricingProps } from './ProductSummaryPricing.d';
import { styles } from './ProductSummaryPricing.styles';
import TranslatedText from '@/components/TranslatedText';

const ProductSummaryPricing = forwardRef<
  HTMLDivElement,
  ProductSummaryPricingProps
>((props, ref) => {
  const {
    onSale,
    multipriced,
    showMessage,
    prices,
    isSubscriber,
    isSubscriptionActivationUsed,
    discountable,
    isPrepaid,
  } = props;
  // Set price to primary color, green
  const isSubscriberAndMultiPriced = isSubscriber && multipriced;
  const notSubscriberNoDiscountMultiPrice =
    !isSubscriber && !discountable && multipriced;

  const overridePrimaryColor = {
    color:
      isSubscriptionActivationUsed ||
      notSubscriberNoDiscountMultiPrice ||
      isSubscriberAndMultiPriced
        ? null
        : 'text',
  };

  // TODO: add additional logic for subscriber (RUI-21)
  return (
    <Box
      ref={ref}
      sx={styles({
        onSale,
        showMessage,
        multipriced,
        notSubscriberNoDiscountMultiPrice,
        isSubscriberAndMultiPriced,
        isSubscriptionActivationUsed,
        isPrepaid,
      })}
      className="product-summary-pricing">
      {multipriced && (
        <TranslatedText
          as="span"
          color="text"
          fontWeight="400"
          fontSize={['xs', null, 'sm']}
          letterSpacing="normal"
          className="product-summary__message"
          t="catalog.from"
        />
      )}
      <p className="product-prices__price">
        {onSale ? (
          <>
            <Price>{prices.sale}</Price>
            <Price inactive={true}>{prices.full}</Price>
          </>
        ) : (
          <Price {...overridePrimaryColor}>{prices.full}</Price>
        )}
      </p>
    </Box>
  );
});

ProductSummaryPricing.defaultProps = {
  onSale: false,
  showSubscriberDiscount: false,
  multipriced: true,
  showMessage: false,
  isPrepaid: false,
};

export default ProductSummaryPricing;
